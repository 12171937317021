import React from 'react'

import {Navbar, Nav, NavDropdown, Container} from 'react-bootstrap';
import {StyledNavItem, StyledNavLink} from "../styles/HeaderStyle";

import { useTranslation } from 'react-i18next';

import '../css/Header.css'

import Logo from '../assets/FAVICON.svg'

function Header() {
    const [t, i18n] = useTranslation('default');

    return (
        <header id='top-navbar-wrap'>
            <Navbar>
                <Navbar.Brand href='/'>
                    <img
                        src={Logo}
                        alt='Blurtle logo'
                        width='53'
                        height='53'
                    />
                    Blurtle
                </Navbar.Brand>

                <Nav variant="pills">
                    {
                        /* Future feature
                        <Nav.Item>
                            <Nav.Link className='default-nav-link' href='/feature'>Feature</Nav.Link>
                        </Nav.Item>
                        */
                    }
                    <Nav.Item>
                        <Nav.Link className='default-nav-link' href='/manual'>{t("header.how_to_use")}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='default-nav-link' href='/faq' >{t("header.faq")}</Nav.Link>
                    </Nav.Item>
                    <StyledNavItem>
                        <StyledNavLink href='/order'>{t("header.buy_now")}</StyledNavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                        <StyledNavLink href='/download'>{t("header.download")}</StyledNavLink>
                    </StyledNavItem>
                    <NavDropdown title={t("lang_name")} color='#FFFFFF'>
                        <NavDropdown.Item onClick={() => i18n.changeLanguage('ko')/*.then(() => {window.location.reload()})*/}>Korean</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => i18n.changeLanguage('en')/*.then(() => {window.location.reload()})*/}>English</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar>
        </header>
    );
}

export default Header;