import React from "react";
import {CodeActivation} from '../components/DownloadSection'

class Download extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <CodeActivation/>
    )
  }
}

export default Download;