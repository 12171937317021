import React from "react";
import {
    FAQParagraph, ContactInfo
} from '../components/FAQSection';


function FAQ() {
    return (
        <div style={{padding: "200px 400px"}}>
            <FAQParagraph/>
            <ContactInfo/>
        </div>
    )
}

export default FAQ;