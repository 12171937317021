import React, { useState, useEffect } from "react";
import {
  ContainerBox,
  ContainerInnerBox,
  HeadTitle,
  InputBox,
  InputTitle,
  AgreeInputBox,
  ProceedBox,
  NotiBox,
  NotiBoxListItem,
  NotiBoxParagraphItem,
} from "../styles/OrderStyle";
import { useTranslation, Trans } from "react-i18next";
import useGeoLocation from "react-ipgeolocation";
import axios from "axios";

function OrderComponent(props) {
  const [t, i18n] = useTranslation("default");

  const [payInfo, setPayInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    tel: "010-0000-0000",
    address: "",
    agree: false,
  });

  const [unit, setUnit] = useState(t("order.proceed.unit"));
  const [price, setPrice] = useState(t("order.proceed.price"));
  const [pgType, setPGType] = useState(t("order.proceed.pg_type"));
  const [locationLoaded, setLocationLoaded] = useState(false);
  const [countryCode, setCountryCode] = useState();
  const geoLocation = useGeoLocation();

  // Add jquery & iamport
  const portone = document.createElement("script");
  portone.src = "https://cdn.portone.io/v2/browser-sdk.js";
  document.head.appendChild(portone);

  // PortOne 결제: PAYPAL
  // responseData는 api/order-start의 결과
  async function portOnePaymentPaypal(responseData) {
    // console.log("handleCreateOrder : " + responseData.merchant_uid);
    // console.log("responseData.currency_unit : " + responseData.currency_unit);
    // console.log("responseData.product_price : " + responseData.product_price);

    // portone-ui-container의 display를 block으로 변경
    const portoneContainer = document.querySelector(".portone-ui-container");
    if (portoneContainer) {
      portoneContainer.style.display = "block";
    }

    const requestData = {
      uiType: "PAYPAL_SPB",
      storeId: "store-478b06c8-48b3-44b3-9b26-483f561f6dc9", // portone.io 좌상단
      channelKey: process.env.REACT_APP_CHANNEL_KEY_PAYPAL,
      paymentId: responseData.merchant_uid,
      orderName: responseData.product_name,
      totalAmount: Math.trunc(responseData.product_price * 100), // Paypal은 소수점없이 1/100값 그대로
      currency: "CURRENCY_USD",
    };
    const response = await PortOne.loadPaymentUI(requestData, {
      onPaymentSuccess: async (response) => {
        // 결제가 성공적으로 완료되었을 때

        // order-done 처리
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            payment_id: responseData.merchant_uid,
          },
        };

        try {
          const completeResponse = await axios.post(
            `${process.env.REACT_APP_API_ROOT}/complete`,
            {},
            config
          );
          // console.log("/complete result : " + completeResponse);

          // 결제 완료 페이지
          window.location.href = `/order-done?order_id=${responseData.merchant_uid}&email=${responseData.buyer_email}`;
        } catch (error) {
          console.error("Error fetching price info:", error);
        }
      },
      onPaymentFail: (error) => {
        alert(t("order.dialog.alert3"));
        console.error(error.message);
      },
    });
  }

  // PortOne 결제 : NICE PAY
  // responseData는 api/order-start의 결과
  async function portOnePayment(responseData) {
    // console.log("handleCreateOrder : " + responseData.merchant_uid);
    // console.log("responseData.currency_unit : " + responseData.currency_unit);

    const data = {
      storeId: "store-478b06c8-48b3-44b3-9b26-483f561f6dc9", // portone.io 좌상단
      paymentId: responseData.merchant_uid, // 결제ID
      orderName: responseData.product_name, // 상품명
      totalAmount: responseData.product_price, // 가격
      currency: responseData.currency_unit, // 단위
      channelKey: process.env.REACT_APP_CHANNEL_KEY_NICEPAY,
      payMethod: "CARD",
      customer: {
        fullName: responseData.buyer_name,
        email: responseData.buyer_email,
      },
      redirectUrl: `https://${process.env.REACT_APP_HOST}/order-done`,
    };

    try {
      const response = await PortOne.requestPayment(data);

      if (response.code != null) {
        // 오류 발생
        return alert(response.message);
      }

      // 결제가 성공적으로 완료되었을 때

      // order-done 처리
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          payment_id: responseData.merchant_uid,
        },
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ROOT}/complete`,
          {},
          config
        );
      } catch (error) {
        console.error("Error fetching price info:", error);
      }
      console.log("/complete result : " + response);

      // 결제 완료 페이지
      window.location.href = `/order-done?order_id=${responseData.merchant_uid}&email=${responseData.buyer_email}`;
    } catch (error) {
      alert(t("order.dialog.alert3") + error.message);
      console.error(error.message);
    }
  }

  const handleChange = (event) => {
    const { id, type, checked, value } = event.target;
    setPayInfo((prevPayInfo) => ({
      ...prevPayInfo,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  // 결제 하기 눌렀을 때 호출되는 함수
  const handleSubmit = (event) => {
    // 국가코드: IP 주소를 기반으로 위치 정보 제공 서비스에서 가져옴
    var country_code = geoLocation.country;
    if (country_code == undefined) country_code = "us";

    // console.log("process.env.NODE_ENV : " + process.env.NODE_ENV);
    // console.log("i18n.language.toLowerCase() : " + i18n.language.toLowerCase()); // ko, en
    // console.log("geoLocation.country : " + geoLocation.country); // KR

    if (payInfo.agree == false) {
      alert(t("order.dialog.alert1"));
      return;
    }

    if (!payInfo.first_name || !payInfo.last_name || !payInfo.email) {
      alert(t("order.dialog.alert2"));
      return;
    }

    // 최소한의 데이터 (메일, 이름, 국가코드)
    const data = {
      user_email: payInfo.email,
      user_profile_name: payInfo.first_name + "." + payInfo.last_name,
      country_code: country_code,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    event.preventDefault();

    // 주문 생성
    // api/order-start
    axios
      .post(`${process.env.REACT_APP_API_ROOT}/order-start`, data, config)
      .then((response) => {
        if (response.data == null) {
          alert(t("order.dialog.alert4"));
          return;
        }

        if (countryCode == "KR") {
          // PortOne 결제 진행하는 부분: 나이스
          portOnePayment(response.data);
        } else {
          // PortOne 결제 진행하는 부분: 페이팔
          portOnePaymentPaypal(response.data);
        }
      })
      .catch((error) => {
        console.debug("전송에 실패하였습니다." + data);
      });
  };

  // 위치 정보가 로드되었는지 확인
  useEffect(() => {
    if (geoLocation && geoLocation.country) {
      setCountryCode(geoLocation.country);
      setLocationLoaded(true);
    }
  }, [geoLocation]);

  // 가격 로드: 동적으로 API로 로드
  // 위치 정보가 로드되었을 때만 데이터 가져오기
  useEffect(() => {
    async function fetchData() {
      const productName = "blurtle-app";
      var locale = countryCode.toLowerCase();
      if (locale != "kr") locale = "us";

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          product_id: `${productName}-${locale}`,
        },
      };

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ROOT}/price_info`,
          config
        );
        setUnit(response.data["unit"]);
        setPrice(response.data["price"]);
        setPGType(i18n.t("order.proceed.pg_type"));
      } catch (error) {
        console.error("Error fetching price info:", error);
        setPrice(t("order.proceed.price"))
        setUnit(t("order.proceed.unit"))
      }
    }

    if (locationLoaded) {
      fetchData();
    }
  }, [locationLoaded, countryCode]);

  return (
    <ContainerBox>
      <ContainerInnerBox>
        <HeadTitle>{t("order.head_title")}</HeadTitle>
        <NotiBox>
          <h2>{t("order.noti.before_your_buy.title")}</h2>
          <ul>
            {t("order.noti.before_your_buy.contents", {
              returnObjects: true,
            }).map((item, idx) => (
              <NotiBoxListItem key={idx} text={item} />
            ))}
          </ul>
        </NotiBox>
        <NotiBox>
          <h2>{t("order.noti.privacy_policy.title")}</h2>
          <div>
            {t("order.noti.privacy_policy.contents", {
              returnObjects: true,
            }).map((item, idx) => (
              <NotiBoxParagraphItem key={idx} text={item} />
            ))}
          </div>
        </NotiBox>
        <NotiBox>
          <h2>{t("order.noti.refund_policy.title")}</h2>
          <ul className={"refundContentsBox"}>
            {t("order.noti.refund_policy.contents", {
              returnObjects: true,
            }).map((item, idx) => (
              <NotiBoxParagraphItem key={idx} text={item} />
            ))}
          </ul>
        </NotiBox>
        <div style={{ marginTop: "80px" }}>
          <InputBox>
            <InputTitle>
              <label>{t("order.input.email")}</label>
            </InputTitle>
            <div>
              <input
                id={"email"}
                name={"email"}
                className={"email"}
                type={"email"}
                maxLength={100}
                value={payInfo.email}
                onChange={handleChange}
              ></input>
            </div>
          </InputBox>
          <InputBox>
            <InputTitle>
              <label>{t("order.input.name")}</label>
            </InputTitle>
            <div>
              {i18n.language.toLowerCase() === "ko" ? (
                <>
                  <div className={"nameBox"}>
                    <input
                      id={"last_name"}
                      name={"last_name"}
                      type={"text"}
                      maxLength={50}
                      value={payInfo.last_name}
                      onChange={handleChange}
                    ></input>
                    <label htmlFor={"last_name"}>
                      {t("order.input.last_name")}
                    </label>
                  </div>
                  <div className={"nameBox"}>
                    <input
                      id={"first_name"}
                      name={"first_name"}
                      type={"text"}
                      maxLength={50}
                      value={payInfo.first_name}
                      onChange={handleChange}
                    ></input>
                    <label htmlFor={"first_name"}>
                      {t("order.input.first_name")}
                    </label>
                  </div>
                </>
              ) : (
                <>
                  <div className={"nameBox"}>
                    <input
                      id={"first_name"}
                      name={"first_name"}
                      type={"text"}
                      maxLength={50}
                      value={payInfo.first_name}
                      onChange={handleChange}
                    ></input>
                    <label htmlFor={"first_name"}>
                      {t("order.input.first_name")}
                    </label>
                  </div>
                  <div className={"nameBox"}>
                    <input
                      id={"last_name"}
                      name={"last_name"}
                      type={"text"}
                      maxLength={50}
                      value={payInfo.last_name}
                      onChange={handleChange}
                    ></input>
                    <label htmlFor={"last_name"}>
                      {t("order.input.last_name")}
                    </label>
                  </div>
                </>
              )}
            </div>
          </InputBox>
        </div>
        <AgreeInputBox>
          <label style={{ whiteSpace: "pre-line" }} htmlFor={"agree"}>
            {t("order.input.agree_checkbox")}
          </label>
          <input id={"agree"} name={"agree"} type={"checkbox"} checked={payInfo.agree} onChange={handleChange}></input>
        </AgreeInputBox>
        <ProceedBox>
          <div>
            <span className={"black"}>{t("order.proceed.total")}</span>
            <span className={"blue"}>
              {parseFloat(price).toLocaleString()} {unit}
            </span>
          </div>

          <button onClick={handleSubmit}>{t("order.proceed.btn_txt")}</button>
          <div className="portone-ui-container"></div>
        </ProceedBox>
      </ContainerInnerBox>
    </ContainerBox>
  );
}

export { OrderComponent };
