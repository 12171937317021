import styled from "styled-components";
import { Link } from "react-router-dom";
import footer from "../components/Footer";

export const FieldAndValue = styled.div`
  color: #ffffff;
  font-size: 15px;
  display: flex;
  column-gap: 10px;
  line-height: 200%;
`

export const FieldAndValuesBox = styled.div`
  display: flex;
  column-gap: 6px;
  line-height: 200%;
`

export const FieldAnchor = styled.a`
  color: #ffffff;
  font-size: 15px;
  text-decoration: none;
  line-height: 200%;

  &:hover{
    color: #ffffff;
  }
`

export const FieldWhite = styled.p`
  color: #ffffff;
  font-size: 15px;
  margin: 0;
`
export const FieldGrey = styled.p`
  color: #B4B4B4;
  font-weight: 700;
  font-size: 15px;
  margin: 0;
`

export const Value = styled.p`
  font-family: Inter;
  font-style: normal;
  font-size: 15px;
  color: #B4B4B4;
  margin: 0;
  line-height: 200%;
`

export const TermsAndPolicy = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.0025em;

  color: #232323;
  
  a {
    color: #FFFFFF;
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
    }
  };
`