import React from 'react';
import ProgressBar from "react-scroll-progress-bar";
import Header from './components/Header'
import Footer from './components/Footer';
import Home from './pages/HomePage';
import Feature from './pages/FeaturePage';
import Manual from './pages/ManualPage';
import Support from './pages/SupportPage';
import Order from './pages/OrderPage';
import Download from './pages/DownloadPage';
import OrderDone from './pages/OrderDonePage';
import Term from './pages/TermPage';
import Policy from './pages/PolicyPage';
import Error from './pages/ErrorPage';
import FAQ from './pages/FAQPage';

import {
    Route,
    Routes,
} from "react-router-dom";

import './App.css'
import RouteChangeTracker from './components/RouteChangeTracker';


function App() {

    RouteChangeTracker();

    return (
        <div className={'App'}>
            <ProgressBar />
            <Header/>
            <div className={'contentWrapper'}>
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route exact path="/feature" element={<Feature/>}/>
                    <Route exact path="/manual" element={<Manual/>}/>
                    <Route exact path="/support" element={<Support/>}/>
                    <Route exact path="/faq" element={<FAQ/>}/>
                    <Route exact path="/order" element={<Order/>}/>
                    <Route exact path="/download" element={<Download/>}/>
                    <Route exact path="/order-done" element={<OrderDone/>}/>
                    <Route exact path="/term" element={<Term/>}/>
                    <Route exact path="/policy" element={<Policy/>}/>
                    <Route exact path="/error" element={<Error/>}/>
                </Routes>
            </div>
            <Footer/>   
        </div>
    )
}

export default App
