import styled, { css } from 'styled-components';


export const ContainerBox = styled.main`
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(208, 244, 255, 0) 0%, #a6e4f6 99.36%);
`;

export const ContainerInnerBox = styled.div`
    max-width: 1140px;
    margin: 0 auto 100px;
    text-align: center;
`;

export const HeadTitle = styled.h1`
    padding: 100px 20px 30px 20px;
    word-break: keep-all;
    font-family: 'Inter';
    font-style: normal;
    font-size: 44px;
    font-weight: 800;
    color: #00BEF2;
`

export const CompleteTxtBox = styled.div`
    width: 60%;
    margin: 20px auto;
    font-family: 'Inter';
    font-style: normal;

    ${props =>
      props.lang === 'ko' &&
      css`
        font-size: 15px;
        letter-spacing: 0.055em;
      `}

    ${props =>
      props.lang === 'en' &&
      css`
        font-size: 16px;
        letter-spacing: 0.035em;
      `}

    p {
      color: rgba(0, 0, 0, 0.5);

      
      strong {
        font-weight: 400;
        color: #000000;
        text-decoration: underline;
      }
    }

    a {
      color: #000000;
      text-decoration: underline;
    }
`

export const BackToMainBox = styled.div`
    width: 50%;
    margin: 40px auto 150px;

    button {
      width: 100%;
      height: 40px;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      background: #00BEF2;
      border: 1px solid #00BEF2;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
`