import styled, { css } from 'styled-components';

export const SloganH1 = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  text-align: center;
  color: ${({color}) => (color ? color : '#00BEF2')};
`

export const BackgroundNeon = styled.div`
  display: flex;
  position: absolute;
  content: '';
  background: linear-gradient(${({degree}) => (degree ? degree : 90)}deg,
  rgba(71, 192, 83, 0.3) 20%,
  rgba(100, 145, 211, 0.359375) 40%,
  rgba(3, 136, 192, 0.391667) 60%,
  rgba(36, 168, 96, 0.31569) 80%,
  rgba(255, 197, 1, 0.3) 100%);
  width: ${({width}) => (width ? width : '1480px')};
  height: ${({height}) => (height ? height : '920px')};
  filter: blur(64px);
  z-index: -1;
`;

export const CatalogBackground1 = styled.div`
  display: flex;
  position: absolute;
  content: '';
  background: linear-gradient(${({degree}) => (degree ? degree : 90)}deg,
  rgba(71, 192, 83, 0.3) 20%,
  rgba(100, 145, 211, 0.359375) 40%,
  rgba(3, 136, 192, 0.391667) 60%,
  rgba(36, 168, 96, 0.31569) 80%,
  rgba(255, 197, 1, 0.3) 100%);
  width: ${({width}) => (width ? width : '1480px')};
  height: ${({height}) => (height ? height : '920px')};
  z-index: -1;
  border-radius: 100px;
`;