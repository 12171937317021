import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { LinearProgress, Typography } from "@mui/material";

import {
    ContainerBox, ContainerInnerBox,
    HeadTitle, InputBox, CustomTextField, ButtonBox
    // StyledCenterBoldH1,
    // StyledCenterDiv,
    // StyledSubmitButton,
    // Trembling,
    // TremblingH3
} from "../styles/DownloadStyle";
import axios from 'axios';
import { ProgressBar } from "react-bootstrap";


function CodeActivation() {

    const [t, i18n] = useTranslation('default');
    const [licenseKey, setLicenseKey] = useState("")
    const [keyUnvalidness, setKeyUnvalidness] = useState(false);
    const navigate = useNavigate()
    const [progress, setProgress] = useState(0)

    const handleChange = event => {
        setLicenseKey(event.target.value);
    };

    const handleSubmit = event => {
        if (licenseKey.length == 0) {
            alert('Please input valid license key.')
        }

        const data = {
            "license_key": licenseKey
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Content-Disposition': 'attachment'
            },
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round(progressEvent.loaded * 100 /
                    progressEvent.total);

                setProgress(percentCompleted)
            },
        };

        setKeyUnvalidness(true)

        axios
            .post(`${process.env.REACT_APP_API_ROOT}/download`, data, config)
            .then((response) => {
                if (response.status == "204"){
                    setKeyUnvalidness(true)
                    alert("Unvalid license key.")
                }
                else {
                    setKeyUnvalidness(false)
                    const filename = response.headers['content-disposition'].split(" filename=")[1]
                    const blob = new Blob([response.data], {type: 'application/octet-stream'});
                    const fileObjectUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = fileObjectUrl;
                    link.style.display = "none";
                    link.download = filename;

                    // Add link on body and force trigger click event
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    window.URL.revokeObjectURL(fileObjectUrl);
                }
            })
            .catch(error => {
                alert(error.message)
                navigate("/error")
            })
    }

    return (
        <ContainerBox>
            <ContainerInnerBox>
                <HeadTitle>{t("download.head_title")}</HeadTitle>
                <InputBox>
                    <CustomTextField 
                        placeholder={t("download.placeholder")}
                        onChange={handleChange}
                        value={licenseKey}
                        />
                </InputBox>
                <ButtonBox>
                    <button type="submit" onClick={ handleSubmit }>{t("download.btn_txt")}</button>
                </ButtonBox>
                <LinearProgress variant="determinate" value={progress} style={{height: 20}}/>
                <Typography>{progress.toString()}%</Typography>
            </ContainerInnerBox>
        </ContainerBox>    

    );
}

export {CodeActivation};