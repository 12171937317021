import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useLocation, Navigate } from "react-router-dom";

import {
  ContainerBox,
  ContainerInnerBox,
  HeadTitle,
  CompleteTxtBox,
  BackToMainBox,
} from "../styles/OrderDoneStyle";
import check_icon from "../assets/OrderDoneSection/check_icon.png";

function OrderInfo(props) {
  console.log("OrderDoneSection.js");

  const search = useLocation().search;
  const order_id = new URLSearchParams(search).get("order_id");
  const email = new URLSearchParams(search).get("email");
  const payment_id = new URLSearchParams(search).get("payment_id");

  const [t, i18n] = useTranslation("default", { useSuspense: false });
  const [order_num, setOrderNum] = useState(order_id);
  const [user_email, setUserEmail] = useState(email);

  /*
  if (order_id == null || email == null || payment_id == null) {
    return <Navigate to="/" />;
  }
  */

  return (
    <ContainerBox>
      <ContainerInnerBox>
        <HeadTitle>{t("order-done.head_title")}</HeadTitle>
        <div>
          <img src={check_icon}></img>
        </div>
        <CompleteTxtBox lang={i18n.language}>
          <p>
            <Trans
              i18nKey={"default:order-done.complete_txt"}
              values={{ order_num: order_num, user_email: user_email }}
            />
          </p>
          <a href={t("order-done.mailto")}>{t("order-done.shoot_email")}</a>
        </CompleteTxtBox>
        <BackToMainBox>
          <button
            onClick={() => {
              window.location.href = "/";
            }}
          >
            {t("order-done.back_btn_txt")}
          </button>
        </BackToMainBox>
      </ContainerInnerBox>
    </ContainerBox>
  );
}

export { OrderInfo };
